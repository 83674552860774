import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoaderService } from '@layouts/loader/loader.service';
import { TranslateService } from '@ngx-translate/core';
import { CartMappingService } from '@services/mapping/cart-mapping.service';
import { DetailOrderGroupCreateDto } from '@services/ws/order/order/signatures/createManagerOrder/detail-order-group-create-dto';
import { ToastService } from '@shared/components/toast/toast.service';
import * as LOCALSTORAGE from '@shared/constants/local-storage.constants';
import { CurrencyEnum } from '@shared/enums/currency.enum';
import { CustomerTypeEnum } from '@shared/enums/customer-type.enum';
import { OrderCustomerTypeEnum } from '@shared/enums/order-customer-type.enum';
import { OrderDeliveryTypeEnum } from '@shared/enums/order-delivery-type.enum';
import { OrderFormatEnum } from '@shared/enums/order-format.enum';
import { OrderTypeEnum } from '@shared/enums/orders-type.enum';
import { PaymentMethodEnum } from '@shared/enums/payment-method.enum';
import { ProductTypeEnum } from '@shared/enums/product-type.enum';
import { UserTypeEnum } from '@shared/enums/user-type.enum';
import { ErrorCodeApiEnum } from '@shared/models/api/enums/error-code-api.enum';
import { HttpStatusEnum } from '@shared/models/api/enums/http-status.enum';
import { ErrorApi } from '@shared/models/api/error/error.model';
import { CartSummaryDto } from '@shared/models/api/order/cart/cart-summary-dto.model';
import { OrderExpensesDto } from '@shared/models/api/order/estimateOrderExpenses/order-expenses-dto.model';
import { Amount } from '@shared/models/common/amount.model';
import { Cart } from '@shared/models/create-order/cart.model';
import { OrderItem } from '@shared/models/create-order/order-item.model';
import { Order } from '@shared/models/create-order/order.model';
import { CommissionData } from '@shared/models/customer/commissions-data.model';
import { Customer } from '@shared/models/customer/customer.model';
import { DetailOrderEstimateSummary } from '@shared/models/order/detail-order-estimate-summary';
import { User } from '@shared/models/user/user.model';
import { CustomerStore } from '@shared/stores/customer.store';
import { DigitalCourseStore } from '@shared/stores/digital-course.store';
import { RedirectionStore } from '@shared/stores/redirection.store';
import { UserStore } from '@shared/stores/user.store';
import { BehaviorSubject, combineLatest, EMPTY, of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, concatMap, filter, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ErrorService } from './error/error.service';
import { OrderControllerService } from './ws/order/order/order-controller.service';
import { ManagerOrderCreateDto } from './ws/order/order/signatures/createManagerOrder/manager-order-create-dto.model';
import { OrderBeneficiaryCreateDto } from './ws/order/order/signatures/createManagerOrder/order-beneficiary-create-dto.model';
import { OrderGroupCreateDto } from './ws/order/order/signatures/createManagerOrder/order-group-create-dto.model';
import { PersonalizedMessage, PreviewOrderCreateDto } from './ws/order/order/signatures/createManagerOrder/preview-order-create-dto.model';
import { ProductItemOrderCreateDto } from './ws/order/order/signatures/createManagerOrder/product-item-order-create-dto.model';
import { OrderData } from './ws/order/order/signatures/estimatedOrderExpenses/order-data.model';
import { ProductItemOrderEstimate } from './ws/order/order/signatures/estimatedOrderExpenses/product-item-order-estimate.model';
import { ProductItemPersonalization } from './ws/order/order/signatures/product-item-personalization';
import { CartGroupUpdateDto } from './ws/order/order/signatures/updateCart/cart-group-update-dto.model';
import { CartProductItemUpdateDto } from './ws/order/order/signatures/updateCart/cart-product-item-update-dto.model';
import { CartUpdateDto } from './ws/order/order/signatures/updateCart/cart-update-dto.model';
import { PageStore } from '@shared/stores/page.store';
import { OrderOriginEnum } from '@shared/enums/order-origin.enum';
import { RoutesEnum } from '@shared/models/common/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  user: User;
  currentCustomer: Customer;

  cart$: Observable<Cart>;
  private readonly cartSubject: BehaviorSubject<Cart>;

  private readonly createOrderSubject = new Subject<void>();
  createOrderObservable: Observable<void> = this.createOrderSubject.asObservable();

  private readonly resetCodePromoSubject = new Subject<void>();
  resetCodePromoObs: Observable<void> = this.resetCodePromoSubject.asObservable();

  private readonly resetBadEtabCodeSubject = new Subject<void>();
  resetBadEtabCodeObs: Observable<void> = this.resetBadEtabCodeSubject.asObservable();

  private readonly refreshEstimatedOrderExpensesSubject = new BehaviorSubject<OrderExpensesDto>(null);
  refreshEstimatedOrderExpenses$: Observable<OrderExpensesDto> = this.refreshEstimatedOrderExpensesSubject.asObservable();

  private readonly cartUpdateSubject = new BehaviorSubject<CartUpdateDto>(null);
  cartUpdateObs: Observable<CartUpdateDto> = this.cartUpdateSubject.asObservable();

  constructor(
    private readonly router: Router,
    private readonly orderControllerService: OrderControllerService,
    private readonly cartMappingService: CartMappingService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService,
    private readonly loaderService: LoaderService,
    private readonly orderController: OrderControllerService,
    private readonly errorService: ErrorService,
    private readonly userStore: UserStore,
    private readonly digitalCourseStore: DigitalCourseStore,
    private readonly customerStore: CustomerStore,
    private readonly redirectionStore: RedirectionStore,
    private readonly pageStore: PageStore
  ) {
    const cart: Cart = JSON.parse(localStorage.getItem(LOCALSTORAGE.CART));
    // On annule l'éventuel mode édition
    if (cart) {
      cart.isEditionMode = false;
    }
    localStorage.removeItem(LOCALSTORAGE.EDITED_ORDER_ITEM_INDEX);
    localStorage.removeItem(LOCALSTORAGE.EDITED_ORDER_ITEM_ID);
    this.cartSubject = new BehaviorSubject<Cart>(cart);
    this.cart$ = this.cartSubject.asObservable();
  }

  setCart(cart: Cart) {
    const cartOld: Cart = this.cartSubject.value;
    // on conserve cette info dans le LS
    if (cartOld?.typeUserHC) {
      cart.typeUserHC = cartOld.typeUserHC;
    }
    localStorage.setItem(LOCALSTORAGE.CART, JSON.stringify(cart));
    this.cartSubject.next(cart);
  }

  resetCart() {
    sessionStorage.setItem(LOCALSTORAGE.HAS_CART, '0');
    localStorage.removeItem(LOCALSTORAGE.CART);
    // On réinitialise le panier avec une nouvelle commande de même type produit que la précédente
    const cart = this.cartSubject.value;
    this.cartSubject.next(cart && cart.order ? this.initializeEmptyCart(cart.order.productType) : null);
  }

  setCartFromOrder(pOrder: Order) {
    // crée le panier à partir de la commande
    const cart = this.getCartFromOrder(pOrder);

    this.setCart(cart);
  }

  setCartProduct(productType: ProductTypeEnum, productDefined: boolean, user: User) {
    const currentCart = this.cartSubject.value;
    if (!currentCart || currentCart.itemsCount === 0) {
      const cart = this.initializeEmptyCart(productType);
      // Sur cet appel, on est non connecté et on doit conserver le type du User ('Wordpress')
      // pour le moment ou se connectera et on devra vérifier la concordance Type User Panier Non Connecté et Type User connecté
      cart.typeUserHC = user.type;
      this.setCart(cart);
    } else if (currentCart.order.productType !== productType && productDefined) {
      // un panier de type différent est déjà en cours
      // mais si le produit n'est pas défini c'est qu'on peut garder le panier en cours, car on est sur la
      // redirection dans un parcours non connecté
      const msg = this.translateService.instant('TOKEN.ORDER_EXIST');
      this.toastService.showInfinitToastWithTitle(msg, 'error');
    }
  }

  verifyCart(user: User): boolean {
    const cart = this.cartSubject.value;
    if (!cart) {
      return true;
    }
    // Il peut exister un panier dans le LS si on est arrivé en mode non connecté
    // il faut alors vérifier la compatibilité entre ce panier et le profil connecté
    if (user.type !== cart.typeUserHC) {
      this.resetCart();
      // Si on était HC, on a un typeuserHC renseigné et on est bien incompatible ==> on retourne donc false
      // Si on n'était pas HC mais PRO par exemple et quon se connecte PART,
      // on ne voudra pas dire que le panier commencé est incompatible avec le profil de connexion
      return !!cart.typeUserHC;
    } else {
      return true;
    }
  }

  loadCartFromServer$(managerId: number, customerId: number, user: User): Observable<Order> {
    return this.orderControllerService.readManagerCart(managerId, customerId).pipe(
      tap((cartSummaryDto: CartSummaryDto) => {
        if (cartSummaryDto == null) {
          this.resetCart();
        } else {
          sessionStorage.setItem(LOCALSTORAGE.HAS_CART, '1');
        }
      }),
      // On convertit le CartSummaryDto en objet Cart.
      switchMap((cartSummaryDto: CartSummaryDto) => {
        if (cartSummaryDto == null) {
          return of(null);
        } else {
          return this.cartMappingService.cartSummaryDtoToOrder$(cartSummaryDto, user);
        }
      }),
      tap((order: Order) => {
        this.setCartFromOrder(order);
      }),
      // Pas de panier en base
      catchError(() => {
        this.resetCart();
        return of(null);
      })
    );
  }

  loadCartFromServerForCurrentUser$(): Observable<Order> {
    const user = this.userStore.getCurrentConnectedUser();
    const customer = this.customerStore.getCurrentCustomer();
    return this.loadCartFromServer$(user.managerId, customer.id, user);
  }

  mergeLocalCartWithServer$(managerId: number, customerId: number, user: User): Observable<Order> {
    return this.orderControllerService.readManagerCart(managerId, customerId).pipe(
      tap((cartSummaryDto: CartSummaryDto) => {
        // On a un panier en BDD à comparer
        if (cartSummaryDto != null) {
          // panier local
          const cart = this.cartSubject.value;
          if (!!cart && cart.order && cart.itemsCount > 0 && cartSummaryDto.id !== cart.order.id) {
            // un panier est en cours (hors connexion) alors qu'il en existe un à finaliser en base
            const msg = this.translateService.instant('TOKEN.ORDER_EXIST');
            this.toastService.showInfinitToastWithTitle(msg, 'error');
          }
          // Dans tous les cas on remplace le panier local par le panier du serveur
          sessionStorage.setItem(LOCALSTORAGE.HAS_CART, '1');
        } else {
          // Panier local
          const cart = this.cartSubject.value;
          if (!!cart && cart.itemsCount > 0) {
            // on vient de se connecter suite à une validation de panier, en mode non connecté
            // on envoie le panier local au serveur
            this.onValidateCart(cart);
          } else {
            this.resetCart();
          }
        }
      }),
      switchMap((cartSummaryDto: CartSummaryDto) => {
        if (cartSummaryDto == null) {
          return of(null);
        } else {
          return this.cartMappingService.cartSummaryDtoToOrder$(cartSummaryDto, user);
        }
      }),
      tap((order: Order) => {
        this.setCartFromOrder(order);
      }),
      // Pas de panier en base
      catchError(() => {
        // Panier local
        const cart = this.cartSubject.value;
        if (!!cart && cart.itemsCount > 0) {
          // on vient de se connecter suite à une validation de panier, en mode non connecté
          // on envoie le panier local au serveur
          this.onValidateCart(cart);
        } else {
          this.resetCart();
        }
        return of(null);
      })
    );
  }

  callRefreshEstimatedOrderExpenses(cart: Cart, user: User, currentCustomer: Customer, carrierId: number, discountVoucher: string): void {
    // Panier vide
    if (!cart || !cart.order || !cart.order.orderItems || cart.order.orderItems.length <= 0) {
      this.refreshEstimatedOrderExpensesSubject.next(null);
      return;
    }

    // transforme le cart objet pour estimation
    const signature = new OrderData();

    if (user.type === UserTypeEnum.INDIVIDUAL) {
      signature.customerType = CustomerTypeEnum.INDIVIDUAL;
    } else {
      signature.customerType = CustomerTypeEnum.PROFESSIONAL;
    }
    signature.customerId = currentCustomer?.id;
    signature.orderFormat = cart.order.format;
    signature.orderType = OrderTypeEnum.SPECIALIZED;
    signature.productItems = new Array<ProductItemOrderEstimate>();

    for (const cartItem of cart.order.orderItems) {
      const orderItem = new ProductItemOrderEstimate();
      // En l'etat l'id produit est sur le panier et pas dans l'article :-(
      orderItem.productId = cart.order.productType;
      orderItem.personalization = new ProductItemPersonalization();
      if (cartItem.message || cartItem.message2) {
        orderItem.personalization.personalizedMessage = true;
      }

      // Quantite pour les cheques
      orderItem.productsQuantity = cartItem.bookletQuantity;

      // Quantite pochette
      if (cartItem.pocketQuantity != null) {
        orderItem.pocketsChequeQuantity = cartItem.pocketQuantity;
      }

      // Detail compo carnet
      orderItem.details = [];
      for (const cartDetail of cartItem.orderItemDetails) {
        const orderDetail = new DetailOrderEstimateSummary();
        orderDetail.voucherQuantity = cartDetail.quantity;
        orderDetail.voucherValue = new Amount();
        orderDetail.voucherValue.amount = cartDetail.amount;
        orderDetail.voucherValue.currency = CurrencyEnum.EUR;
        orderItem.details.push(orderDetail);
      }

      // Packaging
      if (cartItem.packaging && cartItem.packaging.id) {
        orderItem.packagingId = cartItem.packaging.id;
      }

      signature.productItems.push(orderItem);
    }

    // En l'etat on n'a gere que du mono site donc on peut utiliser le type utilisateur pour avoir le bon orderDeliveryTypeEnum
    signature.deliveryType =
      user.type === UserTypeEnum.PROFESSIONAL ? OrderDeliveryTypeEnum.COMPANY_ONE_DELIVERY_POINT : OrderDeliveryTypeEnum.BENEFICIARY;
    // Si on est sur l'etape de paiement on peut avoir un code promo et un carrierId
    signature.carrierId = carrierId;
    signature.discountVoucher = discountVoucher;
    this.loaderService.startLoading();
    this.orderControllerService
      .estimatedOrderExpenses(signature)
      .pipe(
        tap((success: OrderExpensesDto) => {
          this.refreshEstimatedOrderExpensesSubject.next(success);
        }),
        catchError((err) => {
          console.log('estimatedOrderExpenses error : ' + err);
          throw err;
        }),
        finalize(() => this.loaderService.stopLoading())
      )
      .subscribe();
  }

  getCartFromOrder(order: Order): Cart {
    const cart = new Cart();
    // Calcul du nombre d'articles et du montant total du panier
    let itemsCount = 0;
    let cartAmount = 0;

    if (order && order.orderItems.length > 0) {
      for (const orderItem of order.orderItems) {
        if (OrderFormatEnum.CARD === order.format) {
          itemsCount = itemsCount + orderItem.orderItemDetails[0].quantity;
          cartAmount = cartAmount + orderItem.orderItemDetails[0].quantity * orderItem.orderItemDetails[0].amount;
        } else {
          // Cas des chèques, on boucle sur les détails articles pour calculer le nombre de chèques
          let checkCount = 0;
          let amountCheckCount = 0;
          for (const orderItemDetail of orderItem.orderItemDetails) {
            checkCount = checkCount + orderItemDetail.quantity;
            amountCheckCount = amountCheckCount + orderItemDetail.quantity * orderItemDetail.amount;
          }

          if (OrderFormatEnum.CHEQUE_BOOKLET === order.format) {
            // On multiplie par le nombre de carnets
            itemsCount = itemsCount + orderItem.bookletQuantity * checkCount;
            cartAmount = cartAmount + orderItem.bookletQuantity * amountCheckCount;
          } else if (OrderFormatEnum.CHEQUE_UNITY === order.format) {
            itemsCount = itemsCount + checkCount;
            cartAmount = cartAmount + amountCheckCount;
          }
        }
      }
    }

    cart.cartAmount = cartAmount;
    cart.itemsCount = itemsCount;

    // Si le panier est vide, on remet son format à null
    if (order && order.orderItems.length === 0) {
      order.format = null;
    }

    cart.order = order;

    return cart;
  }

  onValidateCart(cart: Cart): void {
    this.userStore.user$
      .pipe(
        concatMap((user: User) => {
          if (!user || user.id === '-1') {
            this.redirectionStore.setRedirection(RoutesEnum.PAYMENT);
            this.router.navigate(['/authent/connectionorder']);
            return EMPTY;
          } else {
            return this.customerStore.customer$.pipe(
              filter((customer: Customer) => !!customer),
              tap((customer: Customer) => {
                this.createManagerOrder(cart, user, customer);
              })
            );
          }
        }),
        take(1)
      )
      .subscribe();
  }

  productItemsFromOrderItem(orderItem: OrderItem, productCode: number, format: OrderFormatEnum, user: User): ProductItemOrderCreateDto {
    const res = new ProductItemOrderCreateDto();
    res.preview = new PreviewOrderCreateDto();
    if (orderItem.preview) {
      res.preview.id = orderItem.preview.id;
    }

    if (orderItem.message || orderItem.message2) {
      res.preview.personalizedMessages = new PersonalizedMessage();
      res.preview.personalizedMessages.messageFirstLine = orderItem.message;
      res.preview.personalizedMessages.messageSecondLine = orderItem.message2;
    }

    if (orderItem.event) {
      res.eventId = orderItem.event.id;
    }

    if (orderItem.packaging) {
      res.packagingId = orderItem.packaging.id;
    }

    res.productId = productCode;
    res.format = format;

    res.isPersonalizedByBeneficiary = !!orderItem.beneficiaries && !!orderItem.beneficiaries.length;
    res.isPersonalizedVoucherValueByBeneficiary = false;

    // Si on est un particulier, on prend la catégorie par défaut 128
    if (user.type === UserTypeEnum.INDIVIDUAL) {
      res.useCategoryId = 128;
    } else if (orderItem.event && orderItem.event.useCategories) {
      // Sinon on prend le rayon par defaut
      let defautUseCategory = orderItem.event.useCategories.find(uc => uc.isDefault);
      defautUseCategory = defautUseCategory ? defautUseCategory : orderItem.event.useCategories[0];
      res.useCategoryId = defautUseCategory.id;
    }
    res.groups = this.orderGroupCreateDtoFromOrderItem(orderItem, format);

    return res;
  }

  public setCartUpdateObservable(cartId: number, signature: CartUpdateDto, callApi: boolean, msgToast: string): void {
    const user: User = this.userStore.getCurrentConnectedUser();
    if (callApi && this.canCallApiUpdateCart(signature, user) && this.checkUpdateCartIsPossible(signature)) {
      this.loaderService.startLoading();
      this.orderController.updateCart(cartId, signature).subscribe(
        (success) => {
          // on met à jour le nouveau deliveryPoint dans l'updateCart partagé
          // et on prévient les composants de ce changement
          signature.productItems?.forEach((pdtItemUpdate: CartProductItemUpdateDto) => {
            pdtItemUpdate.groups?.forEach((groupUpdate: CartGroupUpdateDto) => {
              groupUpdate.id = groupUpdate.deliveryPointId;
            });
          });

          if (msgToast) {
            this.toastService.showShortToastWithTitle(msgToast, 'success');
          }

          this.loaderService.stopLoading();
          this.cartUpdateSubject.next(signature);
        },
        (errors) => {
          const prefixeErreurs = 'PAYMENT.ERRORS.';
          const errorsApi: Array<ErrorApi> = this.errorService.getListErrorApi(errors);

          let msg = '';
          errorsApi.forEach((errorApi) => {
            let libelle = `${errors.status} / ${errorApi.code}`;
            switch (errors.status) {
              case HttpStatusEnum.BAD_REQUEST: // 400:
                switch (errorApi.code) {
                  case ErrorCodeApiEnum.INVALID_DISCOUNT_CODE_055:
                    switch (errorApi.attribute) {
                      case 'discountVoucher': // Mauvais code promo
                        let tmp: string = this.translateService.instant(prefixeErreurs + '400_055_DISCOUNT_VOUCHER');
                        tmp = tmp.replace('A_REMPLACER', `<b class="text-danger">${signature.discountVoucher}</b>`);
                        libelle = tmp;
                        this.resetCodePromoSubject.next();
                        break;

                      default:
                        break;
                    }
                    break;

                  case ErrorCodeApiEnum.INVALID_ESTABLISHMENT_CODE_054:
                    switch (errorApi.attribute) {
                      case 'commissionsData.establishmentCode': // Code établissement non reconnu
                        libelle = this.translateService.instant(prefixeErreurs.concat('400_054_ESTABLISHMENTCODE'));
                        break;
                      case 'commissionsData.vendorEstablishmentCode': // Code établissement vendeur non reconnu
                        libelle = this.translateService.instant(prefixeErreurs.concat('400_054_VENDORESTABLISHMENTCODE'));
                        break;
                    }
                    this.resetBadEtabCodeSubject.next();
                    break;
                  case ErrorCodeApiEnum.INVALID_CARRIER_041:
                    if (errorApi.attribute === 'carrierId') {
                      // Pas de transporteur identifié
                      libelle = this.translateService.instant(prefixeErreurs.concat('400_041_CARRIERID'));
                    }
                    break;

                  default:
                    break;
                }
                break;

              case HttpStatusEnum.UNAUTHORIZED: // 401
              case HttpStatusEnum.INTERNAL_SERVER_ERROR: // 500
              default:
                this.toastService.showInfinitToastWithTitle(this.translateService.instant('USUAL.TECHNICAL_ERROR'), 'error');
                break;
            }

            msg += libelle + '<br/>';
          });
          this.loaderService.stopLoading();
          if (errorsApi[0].code === ErrorCodeApiEnum.INVALID_ESTABLISHMENT_CODE_054) {
            this.toastService.showInfinitToastWithTitle(msg, 'info');
          } else {
            this.toastService.showInfinitToastWithTitle(msg, 'error');
          }
        }
      );
    } else {
      // on a pas appelé l'API mais on peut prévenir les autres composant que la signature
      // a changé
      this.cartUpdateSubject.next(signature);
    }
  }

  getCurrentItemsCount(): number {
    const cart = this.cartSubject.value;
    return cart ? cart.itemsCount : 0;
  }

  // Met à jour le statut (en modification ou non) du panier, et envoie l'info aux observateurs
  setCartEditionMode(editionMode: boolean) {
    const cart = this.cartSubject.value;
    cart.isEditionMode = editionMode;
    this.cartSubject.next(cart);
  }

  hasCurrentCart(): boolean {
    const cart = this.cartSubject.value;
    return cart && cart.itemsCount > 0;
  }

  /***
   * Applique toute les regles de calcule de commission
   */
  calculateCommissionsData(commissionsDataOrder: CommissionData): CommissionData {
    // Si particulier pas d'info commission
    if (this.userStore.getCurrentConnectedUser().type === UserTypeEnum.INDIVIDUAL) {
      return null;
    }

    // lecture des commissions infos
    const digitalCourse = this.digitalCourseStore.getCurrentDigitalCourse();

    // Rien à remplir
    if (digitalCourse == null && commissionsDataOrder == null) {
      return null;
    }

    let commissionsDataResult = null;
    // Si on seulement du parcour digital, alors on prend les info tel quel
    if (digitalCourse != null && digitalCourse.seller != null && commissionsDataOrder == null) {
      commissionsDataResult = new CommissionData();
      commissionsDataResult.establishmentCode = digitalCourse.seller.establishmentCode;
      commissionsDataResult.vendorEstablishmentCode = digitalCourse.seller.vendorEstablishmentCode;
      commissionsDataResult.vendorCode = digitalCourse.seller.vendorCode;
    }
    // Si seulement les info de la commande precedente sont remplis, alors on les prend tel quel
    else if ((digitalCourse == null || digitalCourse.seller == null) && commissionsDataOrder != null) {
      commissionsDataResult = commissionsDataOrder;
    } else {
      // Si le code etablissement a changé alors les infos vendeurs doivent etre remise à zero et on ne garde que le parcours digitial
      // Le matricule a l'interieur ne peut pas correspondre vu qu'il serait sur une vendeur d'une autre banque.
      if (
        (commissionsDataOrder != null && commissionsDataOrder.establishmentCode === digitalCourse.seller.establishmentCode) ||
        commissionsDataOrder.vendorEstablishmentCode === digitalCourse.seller.vendorEstablishmentCode
      ) {
        commissionsDataResult = commissionsDataOrder;
      } else {
        commissionsDataResult = new CommissionData();
      }

      // Ecrasement par le parcour digital
      commissionsDataResult.establishmentCode = digitalCourse.seller.establishmentCode;
      commissionsDataResult.vendorEstablishmentCode = digitalCourse.seller.vendorEstablishmentCode;
      commissionsDataResult.vendorCode = digitalCourse.seller.vendorCode;
    }

    return commissionsDataResult;
  }

  // Récupère le panier courant, ainsi que le user et le customer, et appelle l'API pour l'enregistrer.
  createCurrentCart(): Observable<boolean> {
    const cart = this.cartSubject.value;
    return combineLatest([this.userStore.user$, this.customerStore.customer$]).pipe(
      // On ne fait qu'un seul appel
      take(1),
      switchMap(([user, customer]: [User, Customer]) => {
        if (cart && cart.order?.id) {
          // Si le panier a un id, il est déjà créé
          return of(true);
        } else if (!user || user.id === '-1' || !customer) {
          // L'utilisateur n'est pas connecté, impossible de sauvegarder le panier
          throw new Error('NOT_CONNECTED');
        } else {
          const managerOrderCreateDto: ManagerOrderCreateDto = this.getManagerOrderCreateDto(cart, user);
          // On crée le panier en base
          return this.orderControllerService.createManagerOrder(managerOrderCreateDto, user.managerId, customer.id).pipe(
            switchMap(() => {
              // On recharge le panier que l'on vient d'insérer en base pour le mettre dans l'observable, avec son id
              return this.loadCartFromServer$(user.managerId, customer.id, user).pipe(
                tap((order) => {
                  // Si le retour est vide, le panier n'a pas été trouvé en base, c'est anormal
                  if (!order) {
                    this.toastService.showInfinitToastWithTitle(this.translateService.instant('USUAL.TECHNICAL_ERROR'), 'error');
                  }
                })
              );
            }),
            catchError((error) => {
              this.errorService.errorHandling(error);
              throw error;
            })
          );
        }
      }),
      // Si on arrive là sans erreur c'est que tout s'est bien passé, on renvoie true pour confirmer le bon déroulement au composant
      map(() => true)
    );
  }

  getOrigin(): Observable<OrderOriginEnum> {
    return this.pageStore.isFromBimpli$
      .pipe(
        switchMap((isFromBimpli) => {
          return of(isFromBimpli ? OrderOriginEnum.BIMPLI : OrderOriginEnum.CADOSTORE);
        })
      );
  }

  private checkUpdateCartIsPossible(signature: CartUpdateDto): boolean {
    // vérification des champs obligatoires
    return !!(
      signature.carrierId &&
      signature.paymentMethod &&
      signature.billingContact &&
      signature.productItems &&
      signature.productItems.length > 0 &&
      signature.productItems[0].id &&
      signature.productItems[0].groups &&
      signature.productItems[0].groups.length > 0 &&
      signature.productItems[0].groups[0].deliveryPointId
    );
  }

  private orderGroupCreateDtoFromOrderItem(orderItem: OrderItem, format: OrderFormatEnum): Array<OrderGroupCreateDto> {
    const groups = new Array<OrderGroupCreateDto>();
    const orderGroupCreateDto = new OrderGroupCreateDto();

    // productsQuantity : nombre de formule (uniquement pour les commandes CHEQUE_BOOKLET nombre de carnets)
    if (format === OrderFormatEnum.CHEQUE_BOOKLET) {
      orderGroupCreateDto.productsQuantity = orderItem.bookletQuantity;
    }

    // On boucle sur les détails des articles
    const details = new Array<DetailOrderGroupCreateDto>();
    for (const orderItemDetail of orderItem.orderItemDetails) {
      const detailOrderGroupCreateDto = new DetailOrderGroupCreateDto();
      detailOrderGroupCreateDto.voucherQuantity = orderItemDetail.quantity;
      const amount = new Amount();
      amount.currency = CurrencyEnum.EUR;
      amount.amount = orderItemDetail.amount;
      detailOrderGroupCreateDto.voucherValue = amount;
      detailOrderGroupCreateDto.voucherValueId = orderItemDetail.voucherValueId;
      details.push(detailOrderGroupCreateDto);
    }
    orderGroupCreateDto.details = details;

    // Nombre de pochettes (chèques uniquement)
    if (format === OrderFormatEnum.CHEQUE_BOOKLET || format === OrderFormatEnum.CHEQUE_UNITY) {
      orderGroupCreateDto.pocketsChequeQuantity = orderItem.pocketQuantity;
    }

    // Bénéficiaires
    if (!!orderItem.beneficiaries && !!orderItem.beneficiaries.length) {
      orderGroupCreateDto.beneficiaries = [];
      for (const beneficiary of orderItem.beneficiaries) {
        const beneficiaryCreate: OrderBeneficiaryCreateDto = new OrderBeneficiaryCreateDto();
        if (beneficiary) {
          beneficiaryCreate.lastName = beneficiary;
        }
        orderGroupCreateDto.beneficiaries.push(beneficiaryCreate);
      }
    }

    groups.push(orderGroupCreateDto);

    return groups;
  }

  private createManagerOrder(cart: Cart, user: User, customer: Customer) {
    const signature = new ManagerOrderCreateDto();

    if (user.type === UserTypeEnum.INDIVIDUAL) {
      signature.customerType = OrderCustomerTypeEnum.INDIVIDUAL;
      signature.deliveryType = OrderDeliveryTypeEnum.BENEFICIARY;
      // prendre un paymentMethod par défaut si il n'est pas dans le localStorage
      signature.paymentMethod = PaymentMethodEnum.CARD;
    } else {
      signature.customerType = OrderCustomerTypeEnum.PROFESSIONAL;
      signature.deliveryType = OrderDeliveryTypeEnum.COMPANY_ONE_DELIVERY_POINT;
      // prendre un paymentMethod par défaut si il n'est pas dans le localStorage
      signature.paymentMethod = PaymentMethodEnum.WIRE;
    }

    // le type est toujours SPECIALIZED pour les commandes CSV2 (STANDARD pour cadoweb)
    signature.type = OrderTypeEnum.SPECIALIZED;

    // le carrierId peut se trouver dans le localStorage. Sinon, à cette étape, il est vide.
    // le paymentMethod peut se trouver dans le localStorage. Sinon, à cette étape, il est vide.

    // le format est dans les informations du panier
    signature.format = cart.order.format;

    // recupération de l'origin
    this.getOrigin().pipe(
      tap(origin => signature.origin = origin)
    ).subscribe();

    signature.productItems = new Array<ProductItemOrderCreateDto>();
    cart.order.orderItems.forEach((elt) => {
      const pioc = this.productItemsFromOrderItem(elt, cart.order.productType, signature.format, user);
      signature.productItems.push(pioc);
    });

    // Si l'utilisateur a déjà un panier en base, on passe directement au paiement (les petites apis de modif ont fait le boulot)
    // Si ce n'est pas le cas, on crée le panier en base
    if (sessionStorage.getItem('hasCart') === '1') {
      this.createOrderSubject.next();
    } else {
      this.loaderService.startLoading();
      this.orderControllerService
        .createManagerOrder(signature, user.managerId, customer.id)
        .pipe(
          switchMap(() => {
            // On charge le panier que l'on vient d'insérer en base pour le mettre dans le localStorage avec les ids renseignés
            this.user = this.userStore.getCurrentConnectedUser();
            this.currentCustomer = this.customerStore.getCurrentCustomer();

            return this.loadCartFromServer$(this.user.managerId, this.currentCustomer.id, user).pipe(
              tap((order) => {
                // Si le retour est vide, le panier n'a pas été trouvé en base, c'est anormal
                if (!order) {
                  this.toastService.showInfinitToastWithTitle(this.translateService.instant('USUAL.TECHNICAL_ERROR'), 'error');
                }
              })
            );
          }),
          catchError((error) => {
            const apiError: ErrorApi = error.error.errors[0];
            if (apiError.code === ErrorCodeApiEnum.ALREADY_EXIST_022) {
              this.toastService.showInfinitToastWithTitle(this.translateService.instant('ORDERS.CART.SEVERAL_CARTS'), 'error');
            } else {
              this.errorService.errorHandling(error);
            }
            this.loaderService.stopLoading();
            throw error;
          }),
          tap(() => {
            this.router.navigateByUrl(RoutesEnum.PAYMENT);
            this.loaderService.stopLoading();
          })
        )
        .subscribe();
    }
  }

  private initializeEmptyCart(productType: ProductTypeEnum): Cart {
    const cart = new Cart();
    cart.order = new Order();
    cart.order.orderItems = new Array<OrderItem>();
    cart.itemsCount = 0;
    cart.cartAmount = 0;
    cart.order.productType = productType;
    return cart;
  }

  /** permet de savoir si la signature est suffisante pour appeler l'api */
  private canCallApiUpdateCart(signature: CartUpdateDto, user: User): boolean {
    if (
      user.type === UserTypeEnum.PROFESSIONAL &&
      !signature.commissionsData &&
      !signature.commissionsData?.establishmentCode &&
      !signature.commissionsData?.vendorEstablishmentCode
    ) {
      // les PRO doivent avoir un code étab. pour appeler cette API.
      return false;
    }

    let pdtWithoutDeliveryPoints = false;
    signature.productItems?.forEach((pdt: CartProductItemUpdateDto) => {
      pdt.groups?.forEach((grp: CartGroupUpdateDto) => {
        if (!grp.deliveryPointId) {
          // si l'un des pdt n'a pas de delivery point, on ne peut pas appeler l'api.
          pdtWithoutDeliveryPoints = true;
        }
      });
    });

    return !pdtWithoutDeliveryPoints;
  }

  private getManagerOrderCreateDto(cart: Cart, user: User): ManagerOrderCreateDto {
    const signature = new ManagerOrderCreateDto();

    if (user.type === UserTypeEnum.INDIVIDUAL) {
      signature.customerType = OrderCustomerTypeEnum.INDIVIDUAL;
      signature.deliveryType = OrderDeliveryTypeEnum.BENEFICIARY;
      // prendre un paymentMethod par défaut
      signature.paymentMethod = PaymentMethodEnum.CARD;
    } else {
      signature.customerType = OrderCustomerTypeEnum.PROFESSIONAL;
      signature.deliveryType = OrderDeliveryTypeEnum.COMPANY_ONE_DELIVERY_POINT;
      // prendre un paymentMethod par défaut
      signature.paymentMethod = PaymentMethodEnum.WIRE;
    }

    // recupération de l'origin
    this.getOrigin().pipe(
      tap(origin => signature.origin = origin)
    ).subscribe();


    // le type est toujours SPECIALIZED pour les commandes CSV2 (STANDARD pour cadoweb)
    signature.type = OrderTypeEnum.SPECIALIZED;

    // le format est dans les informations du panier
    signature.format = cart.order.format;

    // On renseigne les articles
    signature.productItems = new Array<ProductItemOrderCreateDto>();
    cart.order.orderItems.forEach((orderItem) => {
      const pioc = this.productItemsFromOrderItem(orderItem, cart.order.productType, signature.format, user);
      signature.productItems.push(pioc);
    });

    return signature;
  }
}
